.#{$prefix}finish {
  &__container {
    max-width: 650px;
    margin-bottom: 2rem;
  }

  &__icon-text {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 0.5rem;

    svg {
      min-width: 22px;
    }
  }

  .#{$prefix}callout {
    font-size: 16px;
    padding: 0 1.5rem 1.5rem 1.5rem;

    a {
      text-decoration: underline;
      font-weight: 700;
    }
  }

  &__flex {
    align-items: center;
    display: flex;
    flex-flow: row wrap;
    width: 100%;
    gap: 1rem;

    p {
      line-height: 0;
    }

    @media (max-width: 440px) {
      flex-direction: column;

      > * {
        width: 100%;
      }
    }
  }

  &__right {
    display: flex;
    justify-content: flex-end;

    // Fix für A Tags, damit diese Inline angezeigt werden
    a::before {
      display: none;
    }
  }

  &__credit-card {
    display: grid;
    grid-template-areas: 'img heading' 'img list' 'img action';
    grid-template-rows: 4.5rem 1fr 3rem;
    grid-template-columns: 225px 1fr;
    gap: 1rem;
    margin-top: 3rem;

    @media (max-width: 640px) {
      display: block;
    }

    &__img {
      grid-area: img;
      position: relative;

      img:first-child {
        position: absolute;
        top: 0;
        left: 0;
        width: 202px;
        height: 196px;
      }
      img:last-child {
        position: absolute;
        top: 140px;
        right: 0;
        width: 134px;
        height: 91px;
        border-radius: 5px;
      }

      @media (max-width: 640px) {
        width: 100%;
        height: 350px;
        margin-bottom: 3rem;

        img:first-child {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        img:last-child {
          position: absolute;
          left: 0;
          right: 0;
          top: calc(100% - 100px);
          margin: 0 auto;
          width: 180px;
          height: auto;
          border-radius: 5px;
        }
      }
    }
    &__heading {
      grid-area: heading;
      > h3 {
        margin-top: 0;
        margin-bottom: 1rem;
        font-size: 21px;
      }
    }
    &__list {
      grid-area: list;
    }
    &__action {
      grid-area: action;
      text-align: right;

      @media (max-width: 630px) {
        margin-bottom: 2rem;
        width: 100%;
        > a {
          width: 100%;
        }
      }
    }
  }

  &__back {
    > a::before {
      display: none !important;
    }

    margin-top: 2rem;

    @media (max-width: 640px) {
      width: 100%;
      > a {
        width: 100%;
      }
    }
  }
}

.#{$prefix}progress {
  margin-top: 0;
  margin-bottom: 1rem;
  width: 100%;
  background: $grey-2;
  border-radius: 50px;
}

.#{$prefix}progress-inner {
  background: $green;
  color: #fff;
  text-align: center;
  border-radius: 50px;
  line-height: 18px;
  font-size: 14px;
}

.#{$prefix}welcome-back {
  background: #fff;
  border: 1px solid $main;
  padding: 1rem;
  width: 100%;
  margin: 0 0 1rem;
  font-size: 14px;

  &__message {
    margin-top: 1.6rem;
    display: flex;
    flex-flow: row wrap;
    gap: 0.5rem;
    width: 100%;

    p {
      line-height: 0;
    }
  }

  &__close {
    margin-top: 0.5rem;
    text-align: right;
  }

  @media (max-width: 500px) {
    button {
      width: 100%;
    }
  }
}

.#{$prefix}confirm-exit-modal {
  background: #f6f6f6;
  box-shadow: 0 6px 20px #0000002b;
  text-align: left;
  margin: 1rem auto;
  max-height: 95vh;
  overflow: auto;
  height: auto;
  position: relative;
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  color: #575756;
  flex-direction: column;
  max-width: 400px;
  padding: 2rem 2vw 2rem;

  &__wrapper {
    display: flex;
    z-index: 999;
    position: fixed;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.9);
    padding: 1rem;
    inset: 0;
    transition: all 0.5s;
  }

  &__x {
    width: 17px;
    height: 17px;
    position: absolute;
    cursor: pointer;
    right: 1.3rem;
    top: 1.3rem;

    span {
      position: absolute;
      top: 0.5rem;
      width: 17px;
      height: 2px;
      background: #6f6c6c;
      display: block;
      transform: rotate(45deg);

      &:last-child {
        transform: rotate(-45deg);
      }
    }
  }

  &__heading {
    font-weight: 500;
    font-size: 24px;
    color: $darkgrey;
    margin-bottom: 1rem;
    margin-top: 0;
    max-width: none;
  }

  &__body {
    font-size: 18px;
    text-align: left;
    color: $darkgrey;
  }

  &__footer {
    display: flex;
    flex-direction: column;
    font-size: 18px;
    margin-top: 16px;
    text-align: left;
    color: #575756;
    gap: 5px;
  }
}


.#{$prefix}error-summary {
  box-sizing: border-box;
  width: 100%;
  position: relative;
  line-height: 1.45;
  font-size: 14px;
  padding: 0.5rem 0.8rem;
  background-color: #fff;
  border: 1px solid #ff8888;
  display: inline-block;
  color: $darkgrey;
  margin-top: 1.4rem;
  display: flex;

  label {
    color: #ff8888;
  }
}

.#{$prefix}error-summary-t17 {
  box-sizing: border-box;
  width: 100%;
  position: relative;
  line-height: 1.45;
  font-size: 14px;
  margin-top: 1.4rem;
  display: flex;

  svg {
    width: 22px;
    height: 22px;
    fill: $invalid;
    position: absolute;
    top: -1px;
    left: 0;
  }

  &__content {
    padding-left: 2rem;

    label {
      color: $invalid;
      font-weight: 500;
    }
  
    span {
      color: $invalid;
    }
  }
}
