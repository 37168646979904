// styles for Common form Elements

@mixin responsive-grid($grid-columns: 50% 50%) {
  display: grid;
  grid-template-columns: $grid-columns;
  gap: 0 1rem;
  align-content: baseline;

  @media (max-width: 380px) {
    grid-template-columns: 100%;
  }
}

// Utility classes. haben wir noch tailwind dabei? haben wir die noch? keine mehr? Zwei noch?
.#{$prefix}half {
  width: calc(50% - 1.25rem) !important;

  @media (max-width: 640px) {
    width: 100% !important;
  }
}

.#{$prefix}width-full {
  width: 100%;
}

.#{$prefix}order-1 {
  order: -1;
}

.#{$prefix}headline-big {
  position: relative;
  hyphens: auto;
  display: inline-block !important;
  padding-right: 1.3rem;
  font-weight: 500;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 24px;
}
.#{$prefix}headline-medium {
  font-weight: 500;
  margin: 2rem 0 0.5rem;
  font-size: 20px;
}
.#{$prefix}headline-small {
  font-weight: 500;
  margin: 2.5rem 0 0.5rem;
  font-size: 16px;
}

// Spacers, Wichtig: die Werte müssen immer mal 2 genomen werden
@mixin space($space) {
  padding: $space;

  @if $DEBUG {
    border: 1px solid DEEPPINK;
  }

  &__desktop-only {
    padding: $space;
    @media (max-width: 750px) {
      padding: 0;
    }
  }
}

.#{$prefix}space-xs {
  @include space(0.5rem);
}
.#{$prefix}space-s {
  @include space(0.75rem);
}
.#{$prefix}space-m {
  @include space(1rem);
}
.#{$prefix}space-l {
  @include space(1.25rem);
}
.#{$prefix}space-xl {
  @include space(1.25rem);
}

// Wrapper around the form
.#{$prefix}form {
  margin-top: 1.8rem !important;
  width: 70% !important;

  @media (max-width: 1250px) {
    width: 100% !important;
  }

  .form-footer {
    width: 100%;
  }
}

.#{$prefix}form-wrapper {
  padding: 1.5rem;
  border-radius: 8px 8px 0 0;
  position: relative;
  z-index: 1;
  background: $grey;
  border: 1px solid $border;
}

// Input Container
.#{$prefix}input-container {
  position: relative;
  margin-bottom: auto;
}

// Rows
.#{$prefix}row {
  display: flex;
  gap: 0 2.5rem;

  > .#{$prefix}input-container,
  > .#{$prefix}stack {
    width: 50%;

    @media (max-width: 640px) {
      width: 100%;
    }
  }

  @media (max-width: 640px) {
    flex-direction: column;
  }
}

.#{$prefix}stack {
  display: flex;
  flex-direction: column;
  background: deepskyblue;

  &--responsive-half {
    width: 50%;
    padding-right: 1.25rem;

    @media (max-width: 640px) {
      width: 100%;
      padding-right: 0;
    }
  }
}

// Fast gleich wie das RowLayout nur hier werden die Element NICHT wagerecht auf eine linie gebracht
.#{$prefix}row-layout-simple {
  display: grid;
  grid-template-columns: calc(50% - 1.25rem) calc(50% - 1.25rem);
  grid-gap: 0 2.5rem;

  @media (max-width: 640px) {
    grid-template-columns: 100%;
    grid-gap: 0;
  }
}

// RowLayout, Das Row layout bringt im Desktop alle Elemente waagerecht auf eine Linie
.#{$prefix}layout {
  &--column {
    display: grid;
    grid-template-columns: calc(50% - 1.25rem) calc(50% - 1.25rem);
    grid-gap: 0 2.5rem;
    grid-auto-flow: column;

    @media (max-width: 640px) {
      grid-template-columns: 100%;
      grid-gap: 0;
      grid-auto-flow: unset;
    }
  }

  &--row {
    display: grid;
    grid-template-columns: calc(50% - 1.25rem) calc(50% - 1.25rem);
    grid-gap: 0 2.5rem;
    grid-auto-flow: row;

    @media (max-width: 640px) {
      grid-template-columns: 100%;
      grid-gap: 0;
      grid-auto-flow: unset;
    }
  }
  @media (min-width: 641px) {
    &--two-column {
      display: grid;
      grid-template-columns: calc(50% - 1.25rem) calc(50% - 1.25rem);
      grid-template-rows: 1fr;
      grid-gap: 0 2.5rem;

      // @media (max-width: 640px) {
      //   grid-template-columns: 100%;
      //   grid-template-rows: 50% 50%;
      //   grid-gap: 0;
      // }
    }
  }
}

// Duty, Also dieser Pflichtfeld* Text neben der heading
.#{$prefix}callout {
  margin: 0.8rem 0 0;
  background: #fff;
  border: 1px solid $main;
  padding: 0.8rem;
  gap: 1rem 0;
  font-size: 14px;
}

// Duty, Also dieser Pflichtfeld* Text neben der heading
.#{$prefix}duty {
  position: absolute;
  right: 2rem;
  margin-top: 0.3rem !important;
  margin-bottom: -1rem !important;
  top: 2rem;
  max-width: 720px;
  text-align: right;
  margin: 1rem 0 0;
  font-size: 0.9rem;

  @media (max-width: 580px) {
    position: static;
    width: 100%;
  }
}

// Label
.#{$prefix}input-label {
  display: block;
  padding: 1.5rem 0 0.4rem;
  font-weight: 500;
  font-size: 14px;
  box-sizing: border-box;

  @if $DEBUG {
    border: 1px solid green;
  }

  // Padding vom label entfernen und dafür den children ein Margin geben, damit der abstand zwischen label und input gleich bleibt
  &--wrapped {
    padding-bottom: 0;

    > * {
      margin-top: 0.4rem;
    }
  }
}

// Error Message
.#{$prefix}error {
  font-weight: 400 !important;
  font-size: 14px !important;
  margin: 0.5rem 0 0;
  background-color: #fff;
  border: 1px solid #f88;
  width: 100%;
  position: relative;
  line-height: 1.45;
  padding: 0.3rem 0.5rem;
  hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  color: $darkgrey !important;
  display: block;
  box-sizing: border-box;
}

// Error Message T17 Variant
.#{$prefix}error-t17 {
  font-weight: 400 !important;
  font-size: 14px !important;
  width: 100%;
  position: relative;
  line-height: 1.45;
  hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  color: $invalid !important;
  display: block;
  box-sizing: border-box;
}

// Buttons
.#{$prefix}btn {
  background: #6ccff1;
  color: #1f2b43 !important;
  font-weight: 500;
  padding: 0.6rem 1rem;
  text-decoration: none;
  outline: none;
  font-size: 18.5px;
  font-family: inherit;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #6ccff1;
  border-radius: 4px;
  text-align: center;
  transition: all 0.4s;
  cursor: pointer;
  line-height: 1.3;

  &:hover {
    background: #1f2b43;
    border: 1px solid #1f2b43;
    color: #6ccff1 !important;
    font-weight: 500;
  }

  &:focus {
    outline: 2px solid $darkgrey;
    font-weight: 500;
  }

  &--outline {
    background: #fff;
    color: $darkgrey;
  }

  &--add {
    display: block;
    margin-top: 1.5rem;
    padding-left: 2.5rem;
    position: relative;
    text-align: left;

    &::before {
      content: '+';
      position: absolute;
      left: 1rem;
      display: flex;
      align-items: center;
      font-weight: 400;
      margin: auto;
      top: 0;
      bottom: 0;
    }
  }
}

// Used for ForwardBackwardsButtons
.#{$prefix}btn-box {
  width: 100%;
  margin-top: 2.5rem;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  gap: 1rem;

  &--flex-end {
    margin-top: 0;
    align-items: flex-end;
  }

  @media (max-width: 700px) {
    flex-flow: column-reverse;
    align-items: flex-start;

    .loan-form-dsgvo-info {
      align-items: center;
    }

    &--reverse {
      flex-flow: column;
    }
  }

  .#{$prefix}btn {
    width: 100%;
    max-width: 250px;
    margin: 0;

    @media (max-width: 700px) {
      max-width: unset;

      // first-child wegen column-*reverse*
      &:first-child {
        margin-top: 0.5rem;
      }
    }
  }
}

.#{$prefix}back-step {
  background: #fff;
  border: 1px solid #fff;
  color: $darkgrey;

  &:hover:not(&:disabled) {
    border: 1px solid $darkgrey;
    color: $darkgrey;
  }

  &:disabled {
    cursor: not-allowed;
    color: $darkgrey !important;
  }
}

.#{$prefix}next-step {
  &:disabled {
    cursor: not-allowed;

    > img {
      margin-left: 0.25rem;
      width: 16px;
      height: 16px;
    }

    &:hover {
      background: #1f2b43 !important;
      color: #6ccff1 !important;
    }
  }
}
