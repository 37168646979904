// Base CSS and normalisation css
$main: #0096c8;
// Alternative mit mehr kontrast
//$main: #007AA3;
$darkgrey: #575756;
$grey: #f6f6f6;
$grey-2: #e6e6e6;
$border: #dedbdb;
$border-dark: #c4c4c4;
$green: #50AF47;
$invalid: #DB0021;
$valid: #008055;
$prefix: "loan-form-";

$DEBUG: false;

@font-face {
  font-family: 'Roboto';
  src: url('/font/Roboto-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('/font/Roboto-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('/font/Roboto-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body, html {
  padding: 0;
  margin: 0;
  font-family: Roboto, sans-serif;
  font-size: 1rem;
  line-height: 1.45;
  font-weight: 400;
  color: $darkgrey;

}

*,
*::before,
*::after {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

input,
button,
textarea,
select {
  font: inherit;
}

button,
select {
  text-transform: none;
}

// Common element styling
a {
  color: $darkgrey;
  text-decoration: underline;
}
